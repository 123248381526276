import debounce from 'es6-promise-debounce';

import ACTIONS from '../../actions';
import Api from '../../api';

import {
  addRate,
  addRatesValidation,
  createRatesValidation,
  deleteItemInList,
  getUpdatedDate,
  normalizeAmenities,
  normalizeDescription,
  normalizeEclcConditions,
  normalizeImages,
  normalizeRoomGroups,
  normalizeSimpelFields,
  prepareAmenities,
  prepareDescription,
  preparedRatesFromBackend,
  preparedRatesToBackend,
  preparedRoomTypes,
  prepareEcLcConditions,
  prepareImages,
  prepareRoomGroups,
  prepareSimpelFields,
  prepareTypesCohortAanda,
} from '../../utils/hotels';
import lodashReplaces from '../../utils/lodashReplaces';
import { alertDev } from '../../../utils/alert';
import {
  dayStart,
  diffDays,
  getMoment,
  hoursSubtract,
  dateAddMinutes,
  dateParseZone,
  formatDate,
  momentAddHoursMinutes,
  momentSetHoursAndMinutes,
} from '../../utils/formatDate';
import { extractIntercomChatId } from '../../utils/cartsAndNotes';

import {
  CANCELLATION_PENALTIES,
  CONTACTS_FIELDS,
  DATE_FIELDS,
  INTERCOM_LINK,
  PRICE_VALUES,
  REQUEST_STATUS_LABELS,
  REQUEST_STATUSES,
  VAT_VALUES,
} from '../../constants/hotelBookRequest';
import {
  DEFAULT_EC_LC_CONDITIONS,
  FIELDS,
  LOCALE,
  MEAL_LIST_TYPE,
  NO_MATCHES,
  PROVIDERS_VALUE,
} from '../../constants/hotels';
import { SERVICETYPE } from '../../constants/serviceType';
import {
  ALL_ROOM_INDEX,
  HOTEL_PROVIDER_VALUE,
  INITIAL_ROOM_VALUES,
  VAT_RATES_AS_NUMBERS,
  VAT_RATES_AS_STRINGS,
} from '../../constants/hotel';
import {
  UTC_FULLTIME, FULLTIME, DEFAULTTIME,
} from '../../../constants/time';
import { KEYS } from '../../constants/customAnalytics';

import Validator from './validators/validator';
import { RatesValidator } from './validators/ratesValidator';
import { EcLcConditionsValidator } from './validators/ecLcConditionsValidator';

let store = null;

const DEBOUNCE_TIME = 200;
const autocompleteHotelDebounce = debounce(Api.Trip.autocompleteHotel, DEBOUNCE_TIME);
const autocompleteCompanyDebounce = debounce(Api.Trip.autocompleteCompany, DEBOUNCE_TIME);

const CHAT_ID_LENGTH = 14;
const HOURS_FOR_SUBTRACT = 6;
const MINUTES_TO_ADD = 1;

class HotelService {
  constructor(InitStore) {
    store = InitStore || this.getStore('Hotels');
    this.validator = new Validator();
    this.ratesValidator = new RatesValidator();
    this.ecLcConditionsValidator = new EcLcConditionsValidator();
  }

  get = () => store.getState();

  subscribe = (callback) => store.subscribe(callback);

  setSearchFilter = (label, value) => {
    store.dispatch({
      type: ACTIONS.HOTELS.INPUT_SEARCH,
      label,
      value,
      init: true,
    });
  };

  unSetSearchFilter = () => store.dispatch({
    type: ACTIONS.HOTELS.INPUT_SEARCH,
    label: '',
    value: '',
    init: false,
  });

  autocompleteHotel = async (value, ids) => {
    try {
      return await autocompleteHotelDebounce({ query: value, aId: ids }) || [];
    } catch (e) {
      return [];
    }
  };

  autocompleteCompany = async (value, ids) => {
    try {
      return await autocompleteCompanyDebounce({ query: value, aId: ids }) || [];
    } catch (e) {
      return [];
    }
  };

  autocompleteHotelByContract = (value) => this.autocompleteHotel(value)
    .then((res) => (res ? res.filter((item) => !item.IsRegion) : []));

  autocompleteRegion = debounce(async (value) => {
    try {
      return await Api.Trip.autocompleteRegion(value);
    } catch (e) {
      return [];
    }
  }, DEBOUNCE_TIME);

  prepareDataForHotelRequest = (companyId, accountId) => {
    const {
      templateId,
      hotelInfo: {
        hotelId,
        phone,
        hotelName,
        worksWithVat,
        priceInclVat,
        vatRate,
        isSelfEmployed,
      },
      contacts,
      hotelRates,
      additionalComment,
      intercomChatId,
      editedRequest,
      requestStatus,
      tripId,
      letterSubject,
      cartId,
      selectedRequestAnalytics,
    } = this.get();

    const userAnalytics = selectedRequestAnalytics ? Object.values(selectedRequestAnalytics) : [];

    const emailsToSend = [
      contacts.email,
      contacts.additionalEmail,
    ].filter(Boolean);

    const {
      id, status, sendAt, customerEmail,
    } = editedRequest;

    const reqStatus = () => {
      if (!requestStatus) {
        return status;
      }

      switch (requestStatus) {
        case 0:
          return REQUEST_STATUS_LABELS.Pending;
        case 1:
          return REQUEST_STATUS_LABELS.Confirmed;
        default:
          return REQUEST_STATUS_LABELS.Incomplete;
      }
    };

    const preparedHotelRates = hotelRates.map(({
      id,
      earlyCheckin,
      lateCheckout,
      checkinDate,
      checkinTime,
      checkoutDate,
      checkoutTime,
      price: {
        dailyPrice,
        commission,
        earlyCheckin: earlyCheckinPrice,
        lateCheckout: lateCheckoutPrice,
      },
      amenities: {
        hasFreeCancellation,
        freeCancellationDate,
        cancellationPenalties,
        hasMeal,
        meal,
      },
      userAnalytics,
      employees,
      roomCategory,
      departmentId,
      projectId,
    }) => {
      const fullCheckInDate = momentAddHoursMinutes(checkinDate, checkinTime.split(':')[0], checkinTime.split(':')[1]);
      const fullCheckOutDate = momentAddHoursMinutes(checkoutDate, checkoutTime.split(':')[0], checkoutTime.split(':')[1]);
      const freeCancelDate = hasFreeCancellation && freeCancellationDate ? freeCancellationDate : null;

      const preparedCancellationPenalties = freeCancelDate
        ? [
          {
            from: dateAddMinutes(freeCancelDate, 1),
            base: Number(cancellationPenalties[0].base),
            total: Number(cancellationPenalties[0].total),
            additional: cancellationPenalties[0].additional || false,
          },
        ]
        : [];
      const customRoomAnalytics = userAnalytics ? Object.values(userAnalytics) : [];

      const preparedMealObject = hasMeal
        ? {
          include: meal.include,
          name: meal.name,
          category: Number(meal.category),
          price: Number(meal.price),
        }
        : null;

      return {
        id: Number(id),
        employees: employees,
        roomCategory: roomCategory,
        price: {
          dailyPrice: Number(dailyPrice),
          commission: Number(commission),
          earlyCheckin: Number(earlyCheckinPrice),
          lateCheckout: Number(lateCheckoutPrice),
        },
        amenities: {
          hasMeal: hasMeal,
          meal: preparedMealObject,
          hasFreeCancellation: hasFreeCancellation,
          freeCancellationDate: freeCancelDate,
          cancellationPenalties: preparedCancellationPenalties,
        },
        checkinTime: fullCheckInDate || dayStart(getMoment()),
        checkoutTime: fullCheckOutDate || dayStart(getMoment()),
        earlyCheckIn: earlyCheckin,
        lateCheckout: lateCheckout,
        departmentId: Number(departmentId),
        projectId: Number(projectId),
        userAnalytics: customRoomAnalytics,
      };
    });

    return {
      id,
      companyId,
      templateId,
      hotelInfo: {
        hotelId: Number(hotelId),
        hotelName,
        worksWithVat,
        priceInclVat,
        vatRate: Number(vatRate),
        isSelfEmployed: isSelfEmployed || false,
        emails: emailsToSend,
        phone: phone || contacts.phone,
      },
      hotelRates: preparedHotelRates,
      sendAt: sendAt || null,
      conversationId: Number(intercomChatId),
      customerEmail,
      accountId,
      tripId: tripId || 0,
      cartId: cartId || 0,
      letterSubject,
      additionalComment,
      status: reqStatus(),
      userAnalytics,
    };
  };

  saveHotelBookRequest = async (companyId, accountId) => {
    const data = this.prepareDataForHotelRequest(companyId, accountId);
    const { conversationId } = data;
    const { file } = this.get().customTemplate;

    try {
      const savedRequest = await Api.Trip.saveHotelBookRequest(data);

      if (savedRequest) {
        await this.sendCustomTemplate(savedRequest.id, file);

        this.sendIntercomNote(savedRequest.id, Number(conversationId));
        this.resetCustomTemplate();

        return savedRequest;
      }
    } catch (e) {
      this.resetCustomTemplate();

      return [];
    }
  };

  saveEditedRequest = async (companyId, accountId) => {
    const { file } = this.get().customTemplate;
    const data = this.prepareDataForHotelRequest(companyId, accountId);
    const { id, tripId } = data;

    if (tripId !== 0) {
      data.status = REQUEST_STATUS_LABELS.TripAdded;
    }

    await Api.Trip.saveEditedHotelBookRequest(data);
    await this.sendCustomTemplate(id, file);
    await this.getSavedRequests(companyId);
    await this.changeIsEditing(false);

    this.reset();
  };

  deleteSavedRequest = (requestId) => Api.Trip.deleteSavedRequest(requestId);

  sendIntercomNote = (requestId, conversationId) => {
    Api.IntercomNote.sendIntercomNote(requestId, conversationId);
  };

  searchHotels = async (params) => {
    store.dispatch({
      type: ACTIONS.HOTELS.SEARCH_HOTELS,
      isRequest: true,
      hotelsByRegion: [],
      hotelsByName: [],
    });
    try {
      const res = await Api.Trip.searchHotels(params);
      store.dispatch({
        type: ACTIONS.HOTELS.SEARCH_HOTELS,
        isRequest: false,
        hotelsByRegion: res.filter(({ ByRegion }) => ByRegion),
        hotelsByName: res.filter(({ ByRegion }) => !ByRegion),
      });
    } catch (e) {
      store.dispatch({
        type: ACTIONS.HOTELS.SEARCH_HOTELS,
        isRequest: false,
        hotelsByRegion: [],
        hotelsByName: [],
      });
    }
  };

  updateRateTypes = (res) => store.dispatch({
    type: ACTIONS.HOTELS.GET_RATE_TYPES,
    types: res,
  });

  getRateTypes = async () => {
    try {
      const res = await Api.Trip.getRateTypes();

      this.updateRateTypes(preparedRoomTypes(res));
    } catch (e) {
      this.updateRateTypes([]);
    }
  };

  getTypesCohortAanda = async () => {
    const defaultType = [{ name: 'Не определён', id: 'undefined' }];
    try {
      const res = await Api.Trip.getTypesCohortAanda();
      const typesCohortAanda = !res.length ? defaultType : prepareTypesCohortAanda(res);

      store.dispatch({
        type: ACTIONS.HOTELS.GET_TYPES_COHORT_AANDA,
        payload: typesCohortAanda,
      });
    } catch (e) {
      store.dispatch({
        type: ACTIONS.HOTELS.GET_TYPES_COHORT_AANDA,
        payload: defaultType,
      });
      alertDev(e, 'hotels', 'getTypesCohortAanda');
    }
  };

  setWaitPreloader = (isLoad) => store.dispatch({
    type: ACTIONS.HOTELS.SET_PRELOADER,
    payload: isLoad,
  });

  setAutocompliteModal = (isOpen) => store.dispatch({
    type: ACTIONS.HOTELS.SET_AUTOCOMPLITE_MODAL,
    payload: isOpen,
  });

  resetValidationFields = () => store.dispatch({ type: ACTIONS.HOTELS.RESET_VALIDATION });

  getHotelDataAA = async (hotelId) => {
    this.setWaitPreloader(true);

    try {
      const res = await Api.Trip.getHotelAA(hotelId);
      const {
        Static,
        Matching,
        Static: {
          Additions,
          Amenities,
          AmenitiesEn,
          Images,
          Description,
          DescriptionEn,
          RoomGroups,
          Deleted,
          Id,
        },
      } = res;

      store.dispatch({
        type: ACTIONS.HOTELS.AUTOCOMPLITE_HOTEL,
        formData: {
          simpleFields: prepareSimpelFields(Static, 'simple'),
          matching: prepareSimpelFields(Matching, 'mathcing'),
          additions: prepareSimpelFields(Additions, 'additions'),
          Amenities: prepareAmenities(Amenities),
          AmenitiesEn: prepareAmenities(AmenitiesEn),
          Images: prepareImages(Images),
          Description: prepareDescription(Description),
          DescriptionEn: prepareDescription(DescriptionEn),
          RoomGroups: prepareRoomGroups(RoomGroups),
          Deleted,
          Id,
        },
        hotelLoad: false,
      });

      this.resetValidationFields();
      this.setAutocompliteModal(false);
      this.setWaitPreloader(false);
    } catch (e) {
      alertDev(e, 'hotels', 'getHotelDataAA');

      this.setAutocompliteModal(false);

      store.dispatch({
        type: ACTIONS.HOTELS.GET_HOTEL,
        formData: null,
        hotelLoad: false,
      });
    }
  };

  getHotel = async (id) => {
    store.dispatch({
      type: ACTIONS.HOTELS.GET_HOTEL, hotelLoad: true, formData: null,
    });

    try {
      const res = await Api.Trip.gethotelStatic(id);

      store.dispatch({
        type: ACTIONS.HOTELS.GET_HOTEL,
        formData: {
          simpleFields: prepareSimpelFields(res.Static, 'simple'),
          matching: prepareSimpelFields(res.Matching, 'mathcing'),
          Requisites: prepareSimpelFields(res.Static.Requisites, 'requisites'),
          additions: prepareSimpelFields(res.Static.Additions, 'additions'),
          restrictions: prepareSimpelFields(res.Static.Restrictions, 'restrictions'),
          Amenities: prepareAmenities(res.Static.Amenities),
          AmenitiesEn: prepareAmenities(res.Static.AmenitiesEn),
          Images: prepareImages(res.Static.Images),
          Description: prepareDescription(res.Static.Description),
          DescriptionEn: prepareDescription(res.Static.DescriptionEn),
          RoomGroups: prepareRoomGroups(res.Static.RoomGroups),
          ecLcConditions: prepareEcLcConditions(res.Static.EclcConditions),
          Deleted: res.Static.Deleted,
          Id: res.Static.Id,
          UpdateDate: res.Static.UpdateDate,
          Source: res.Static.Source,
          SourceId: res.Static.SourceId,
          IdSmartway: res.Matching.IdSmartway,
        },
        hotelLoad: false,
      });

      return res;
    } catch (e) {
      alertDev(e, 'hotels', 'getHotel');

      store.dispatch({
        type: ACTIONS.HOTELS.GET_HOTEL,
        formData: null,
        hotelLoad: false,
      });
    }
  };

  updatePlans = (res) => store.dispatch({
    type: ACTIONS.HOTELS.UPDATE_PLANS,
    plans: res,
  });

  deletePlan = (id) => {
    const { Plans } = this.get();
    const plans = Plans.filter(({ id: currentId }) => id !== currentId);

    store.dispatch({
      type: ACTIONS.HOTELS.DELETE_PLAN,
      plans,
    });
  };

  addPlan = (item) => {
    store.dispatch({
      type: ACTIONS.HOTELS.ADD_PLAN,
      plan: item,
    });
  };

  getNewPlans = async () => {
    try {
      const res = await Api.Trip.travellineProviderPlans();

      this.updatePlans(res);
    } catch (e) {
      this.updatePlans([]);
    }
  };

  updateRates = (rates, ratesValidation, isRatesValid) => store.dispatch({
    type: ACTIONS.HOTELS.UPDATE_RATES,
    rates,
    ratesValidation,
    isRatesValid,
  });

  updateBtnRequest = (value) => store.dispatch({
    type: ACTIONS.HOTELS.DO_REQUEST,
    btnRequest: value,
  });

  updateWorksWithVat = (value) => store.dispatch({
    type: ACTIONS.HOTELS.UPDATE_WORKS_WITH_VAT,
    worksWithVat: value,
  });

  updatePriceInclVat = (value) => store.dispatch({
    type: ACTIONS.HOTELS.UPDATE_PRICE_INCL_VAT,
    priceInclVat: value,
  });

  updateDiscount = (value) => {
    store.dispatch({
      type: ACTIONS.HOTELS.UPDATE_DISCOUNT,
      discount: value,
    });
  };

  updateVat = (field, value) => {
    if (field === FIELDS.WORKS_WITH_VAT) {
      return this.updateWorksWithVat(value);
    }

    return this.updatePriceInclVat(value);
  };

  updateBar = (value) => {
    store.dispatch({
      type: ACTIONS.HOTELS.UPDATE_BAR,
      bar: value,
    });
  };

  updateActivatedData = (value) => store.dispatch({
    type: ACTIONS.HOTELS.UPDATE_ACTIVATED_DATA,
    activatedData: value,
  });

  getRates = async () => {
    const { formData: { Id } } = this.get();

    try {
      const {
        rates, plans, works_with_vat, price_incl_vat, discount, BAR, activated_data, connected, hide_rates,
      } = await Api.Trip.travellineProviderRates(Id);

      const preparedRates = rates && rates.length ? preparedRatesFromBackend(rates) : [];
      const ratesValidation = preparedRates.length ? createRatesValidation(preparedRates) : [];
      const isRatesValid = ratesValidation.length ? this.ratesValidator.isValid(ratesValidation, preparedRates) : true;
      const discountValue = discount ? (Number(discount) * 100).toFixed(1) : 0;

      this.updateRates(preparedRates, ratesValidation, isRatesValid);
      this.updatePlans(plans || []);
      this.updateWorksWithVat(works_with_vat);
      this.updatePriceInclVat(price_incl_vat);
      this.updateDiscount(discountValue);
      this.updateBar(BAR);
      this.updateActivatedData(activated_data);
      this.updateConnectionTL(connected);
      this.updateHideRatesTL(hide_rates);

      if (!rates) {
        await this.getNewPlans();
      }
    } catch (e) {
      this.updateRates([], [], true);
      this.updatePlans([]);
    }
  };

  getTravellineRates = async (id) => {
    try {
      const rates = await Api.Trip.travellineProviderRates(id);

      if (rates) {
        const { works_with_vat, price_incl_vat } = rates;

        this.setVatValues(works_with_vat, price_incl_vat);
      }
    } catch (error) {
      store.dispatch({ type: ACTIONS.HOTELS.SET_NO_RATES });
    }
  };

  setVatValues = (worksWithVat, priceInclVat, vatRate) => {
    this.changeWorksWithVat(worksWithVat);
    this.changePriceInclVat(priceInclVat);

    if (vatRate === VAT_RATES_AS_STRINGS.TWENTY) {
      return this.changeVatAmount(VAT_VALUES[2].value);
    }

    if (vatRate === VAT_RATES_AS_STRINGS.SEVEN) {
      return this.changeVatAmount(VAT_VALUES[3].value);
    }

    if (vatRate === VAT_RATES_AS_STRINGS.FIVE) {
      return this.changeVatAmount(VAT_VALUES[4].value);
    }

    if (!worksWithVat && !priceInclVat) {
      return this.changeVatAmount(VAT_VALUES[0].value);
    }

    if (worksWithVat && priceInclVat) {
      return this.changeVatAmount(VAT_VALUES[1].value);
    }
  };

  updateHotel = async (hotel) => {
    const { contract } = this.get();

    this.updateBtnRequest(true);

    try {
      await Api.Trip.updateHotel(hotel);
      await this.updateRateAndPlans();

      if (contract) {
        await this.saveContract(contract);
      }
    } catch (e) {
      this.updateBtnRequest(false);

      return Promise.reject();
    }
  };

  addHotel = async (hotel) => {
    this.updateBtnRequest(true);
    store.dispatch({
      type: ACTIONS.HOTELS.ADD_HOTEL,
      addedHotelId: null,
    });

    try {
      const res = await Api.Trip.addHotel(hotel);

      store.dispatch({
        type: ACTIONS.HOTELS.ADD_HOTEL,
        addedHotelId: res,
      });

      await this.saveNewRateAndPlans();
    } catch (e) {
      this.updateBtnRequest(false);

      store.dispatch({
        type: ACTIONS.HOTELS.ADD_HOTEL,
        addedHotelId: null,
      });

      return Promise.reject();
    }
  };

  saveRateAndPlans = async (id, data) => {
    try {
      await Api.Trip.saveTravellineRatesAndPlans(id, data);

      this.updateBtnRequest(false);
    } catch (e) {
      this.updateBtnRequest(false);

      return Promise.reject();
    }
  };

  updateRateAndPlans = async () => {
    const {
      formData: { Id }, addedHotelId, Plans, Rates, WorksWithVat, PriceInclVat, discount, bar, connected, hideRates,
    } = this.get();

    const rates = Rates.map((item) => {
      const { ChargeValueUnit, ChargeType } = item;

      const chargeType = ChargeValueUnit === 1 ? 0 : ChargeType;

      return {
        ...item,
        ChargeType: chargeType,
      };
    });

    const id = Id || addedHotelId.Id;
    const newDiscount = discount ? discount / 100 : 0;
    const data = {
      rates: preparedRatesToBackend(rates),
      plans: rates.length ? Plans : [],
      works_with_vat: rates.length ? WorksWithVat : false,
      price_incl_vat: rates.length ? PriceInclVat : false,
      discount: rates.length && newDiscount,
      BAR: rates.length ? bar : false,
      connected: rates.length ? connected : false,
      hide_rates: hideRates,
    };

    await this.saveRateAndPlans(id, data);
  };

  saveNewRateAndPlans = async () => {
    const { Rates } = this.get();

    if (!Rates.length) {
      return this.updateBtnRequest(false);
    }

    await this.updateRateAndPlans();
  };

  deleteRateAndPlans = async (id) => {
    const { Rates } = this.get();

    if (!Rates.length) {
      return this.updateBtnRequest(false);
    }

    const data = {
      rates: [],
      plans: [],
      works_with_vat: false,
      price_incl_vat: false,
    };

    await this.saveRateAndPlans(id, data);
  };

  addRate = () => {
    const { Rates, ratesValidation } = this.get();
    const rates = addRate(Rates);
    const changedRatesValidation = addRatesValidation(ratesValidation);

    this.updateRates(rates, changedRatesValidation, false);
  };

  deleteRate = (index) => {
    const { Rates, ratesValidation } = this.get();
    const rates = deleteItemInList(Rates, index);
    const changedRatesValidation = deleteItemInList(ratesValidation, index);
    const isRatesValid = this.ratesValidator.isValid(changedRatesValidation, rates);

    this.updateRates(rates, changedRatesValidation, isRatesValid);
  };

  updateFieldsRate = (field, value, index) => {
    const { Rates, ratesValidation } = this.get();

    const rates = Rates.map((item, ind) => {
      let chargeType = item.ChargeType;

      if (field === FIELDS.RATES_FIELDS.CHARGE_VALUE_UNIT) {
        chargeType = value === 1 ? 0 : 1;
      }

      return ({
        ...item,
        ChargeType: chargeType,
        [field]: ind === index ? value : item[field],
      });
    });

    const mappedRatesValidation = ratesValidation.map((item, ind) => ({
      ...item,
      [field]: ind === index ? this.ratesValidator.validateRates(field, value) : item[field],
    }));

    const isRatesValid = this.ratesValidator.isValid(mappedRatesValidation, rates);

    this.updateRates(rates, mappedRatesValidation, isRatesValid);
  };

  deleteHotel = async (id) => {
    this.updateBtnRequest(true);

    try {
      await Api.Trip.deleteHotel(id);
      await this.deleteRateAndPlans(id);
    } catch (e) {
      this.updateBtnRequest(false);

      return Promise.reject();
    }
  };

  restoreHotel = async (id) => {
    store.dispatch({
      type: ACTIONS.HOTELS.LOADING_RESTORE_HOTEL,
      payload: true,
    });

    try {
      const res = await Api.Trip.restoreHotel(id);

      return res;
    } catch (e) {
      return Promise.reject();
    } finally {
      store.dispatch({
        type: ACTIONS.HOTELS.LOADING_RESTORE_HOTEL,
        payload: false,
      });
    }
  };

  updateConnectionTL = (value) => {
    store.dispatch({
      type: ACTIONS.HOTELS.UPDATE_CONNECTION,
      connected: value,
    });
  };

  updateHideRatesTL = (value) => {
    store.dispatch({
      type: ACTIONS.HOTELS.UPDATE_HIDE_RATES,
      hideRates: value,
    });
  };

  changeField = (value, path) => {
    if (path.includes(FIELDS.MATCHING)) {
      this.setMatchesChange();
    }

    const currentState = this.get();
    const updatedState = lodashReplaces.setWith(currentState, path, value);

    return store.dispatch({
      type: ACTIONS.HOTELS.CHANGE_FIELD,
      updatedState,
    });
  };

  updateEcLcConditions = (ecLcConditions, conditionsValidation, isConditionsValid) => store.dispatch({
    type: ACTIONS.HOTELS.UPDATE_EC_LC_CONDITIONS_FIELD,
    ecLcConditions,
    conditionsValidation,
    isConditionsValid,
  });

  updateEcLcConditionsField = (value, type, field, path) => {
    const currentState = this.get();
    const { conditionsValidation } = currentState;
    const newConditions = lodashReplaces.setWith(currentState.formData.ecLcConditions, path, value);

    const newConditionsValidation = this.ecLcConditionsValidator.validateConditionsFields(
      conditionsValidation, newConditions, type, field, value,
    );
    const isConditionsValid = this.ecLcConditionsValidator.isValid(newConditionsValidation, newConditions);

    this.updateEcLcConditions(newConditions, newConditionsValidation, isConditionsValid);
  };

  changeFields = (paths) => {
    const currentState = this.get();
    const updatedState = paths.reduce((acc, { value, path }) => lodashReplaces.setWith(acc, path, value), currentState);

    store.dispatch({
      type: ACTIONS.HOTELS.CHANGE_FIELD,
      updatedState,
    });
  };

  normalizeHotel = (hotel) => ({
    Matching: {
      ...normalizeSimpelFields(hotel.matching),
      IdSmartway: hotel.IdSmartway,
    },
    Static: {
      ...normalizeSimpelFields(hotel.simpleFields),
      Images: normalizeImages(hotel.Images),
      Requisites: normalizeSimpelFields(hotel.Requisites),
      Additions: normalizeSimpelFields(hotel.additions),
      Restrictions: normalizeSimpelFields(hotel.restrictions),
      Amenities: normalizeAmenities(hotel.Amenities, LOCALE.RU),
      AmenitiesEn: normalizeAmenities(hotel.AmenitiesEn, LOCALE.EN),
      Description: normalizeDescription(hotel.Description),
      DescriptionEn: normalizeDescription(hotel.DescriptionEn),
      RoomGroups: normalizeRoomGroups(hotel.RoomGroups),
      UpdateDate: getUpdatedDate(),
      EclcConditions: normalizeEclcConditions(hotel.ecLcConditions),
      Deleted: hotel.Deleted,
      Id: hotel.Id,
      Source: hotel.Source,
      SourceId: hotel.SourceId,
    },
  });

  reset = () => store.dispatch({ type: ACTIONS.HOTELS.RESET });

  validateField = (field, value, type) => this.validator.isValid(field, value, type);

  isValidForm = (fields) => this.validator.isValidFields(fields);

  getValidation = (fields) => this.validator.getValidation(fields);

  doValidation = async (value, field) => {
    const isValid = this.validateField(field, value);
    await this.changeField(isValid, `${FIELDS.VALIDATION}.${field}`);

    const { validation } = this.get();

    const validForm = this.isValidForm(validation);
    this.changeField(validForm, FIELDS.IS_VALID_FORM);
  };

  getContractHotel = async (id) => {
    try {
      const res = await Api.Trip.getContractHotel(id);

      const contract = res
        ? {
          ...res,
          Discount: res.Discount ? (Number(res.Discount) * 100).toFixed(1) : 0,
        }
        : null;

      store.dispatch({
        type: ACTIONS.HOTELS.UPDATE_CONTRACT_HOTEL,
        payload: contract,
      });
    } catch (e) {
      store.dispatch({
        type: ACTIONS.HOTELS.UPDATE_CONTRACT_HOTEL,
        payload: null,
      });
    }
  };

  updateContractField = (field, value) => {
    store.dispatch({
      type: ACTIONS.HOTELS.UPDATE_CONTRACT_HOTEL_FIELD,
      payload: {
        field,
        value,
      },
    });
  };

  addContract = () => {
    const { formData: { Id } } = this.get();

    store.dispatch({
      type: ACTIONS.HOTELS.ADD_CONTRACT_HOTEL,
      HotelId: Id,
    });
  };

  deleteContract = async (id) => {
    try {
      await Api.Trip.deleteContractHotel(id);

      store.dispatch({
        type: ACTIONS.HOTELS.UPDATE_CONTRACT_HOTEL,
        payload: null,
      });
    } catch (e) {
      alertDev(e, 'hotels', 'deleteContract');
    }
  };

  saveContract = async (contract) => {
    const {
      HotelId, Email, Discount, HasVAT,
    } = contract;

    const body = {
      ...(HotelId && { HotelId }),
      Email,
      Discount: Discount ? Discount / 100 : 0,
      HasVAT,
    };

    try {
      const res = await Api.Trip.saveContractHotel(body);

      store.dispatch({
        type: ACTIONS.HOTELS.UPDATE_CONTRACT_HOTEL,
        payload: res,
      });
    } catch (e) {
      alertDev(e, 'hotels', 'saveContract');
    }
  };

  addEcLcConditions = () => {
    const newEcLcConditionsValidation =
      this.ecLcConditionsValidator.getValidationEcLcConditions(DEFAULT_EC_LC_CONDITIONS);
    const isConditionsValid =
      this.ecLcConditionsValidator.isValid(newEcLcConditionsValidation, DEFAULT_EC_LC_CONDITIONS);

    store.dispatch({
      type: ACTIONS.HOTELS.ADD_EC_LC_CONDITIONS,
      conditionsValidation: newEcLcConditionsValidation,
      isConditionsValid,
    });
  };

  deleteEcLcConditions = async () => {
    store.dispatch({ type: ACTIONS.HOTELS.DELETE_EC_LC_CONDITIONS });
  };

  searchRegions = async (params) => {
    store.dispatch({
      type: ACTIONS.HOTELS.SEARCH_REGIONS,
      isRequest: true,
      regions: [],
    });
    try {
      const res = await Api.Trip.searchRegions(params);
      store.dispatch({
        type: ACTIONS.HOTELS.SEARCH_REGIONS,
        isRequest: false,
        hotelsByRegion: res.filter(({ ByRegion }) => ByRegion),
        hotelsByName: res.filter(({ ByRegion }) => !ByRegion),
      });
    } catch (e) {
      store.dispatch({
        type: ACTIONS.HOTELS.SEARCH_HOTELS,
        isRequest: false,
        hotelsByRegion: [],
        hotelsByName: [],
      });
    }
  };

  changeHasEarlyCheckin = (roomIndex, value) => {
    if (!value) {
      this.changePrices(roomIndex, PRICE_VALUES.earlyCheckin, '');
    }

    const { hotelRates } = this.get();
    const updatedHotelRates = [...hotelRates];

    updatedHotelRates[roomIndex] = {
      ...updatedHotelRates[roomIndex],
      earlyCheckin: value,
    };

    store.dispatch({
      type: ACTIONS.HOTELS.CHANGE_HAS_EARLY_CHECKIN,
      payload: updatedHotelRates,
    });
  };

  changeHasLateCheckout = (roomIndex, value) => {
    if (!value) {
      this.changePrices(roomIndex, PRICE_VALUES.lateCheckout, '');
    }

    const { hotelRates } = this.get();
    const updatedHotelRates = [...hotelRates];

    updatedHotelRates[roomIndex] = {
      ...updatedHotelRates[roomIndex],
      lateCheckout: value,
    };

    store.dispatch({
      type: ACTIONS.HOTELS.CHANGE_HAS_LATE_CHECKOUT,
      payload: updatedHotelRates,
    });
  };

  changeHasFreeCancellation = (roomIndex, value) => {
    if (!value) {
      this.changeFreeCancellationDate(roomIndex, null);
      this.changeCancellationPrices(roomIndex, CANCELLATION_PENALTIES.total, '');
      this.changeCancellationPrices(roomIndex, CANCELLATION_PENALTIES.base, '');
    }

    const { hotelRates } = this.get();
    const updatedHotelRates = [...hotelRates];

    updatedHotelRates[roomIndex] = {
      ...updatedHotelRates[roomIndex],
      amenities: {
        ...updatedHotelRates[roomIndex].amenities,
        hasFreeCancellation: value,
      },
    };

    store.dispatch({
      type: ACTIONS.HOTELS.CHANGE_HAS_FREE_CANCELLATION,
      payload: updatedHotelRates,
    });
  };

  changeHasBreakfast = (roomIndex, value) => {
    if (!value) {
      this.changePrices(roomIndex, PRICE_VALUES.breakfastPrice, '');
    }

    const { hotelRates } = this.get();
    const updatedHotelRates = [...hotelRates];

    const preparedMeal = value
      ? {
        include: true,
        name: 'завтрак',
        category: 1,
        price: 0,
      }
      : null;

    updatedHotelRates[roomIndex] = {
      ...updatedHotelRates[roomIndex],
      amenities: {
        ...updatedHotelRates[roomIndex].amenities,
        hasMeal: value,
        // TODO: позже здесь появятся обеды, ужины; пока так захардкодить надо
        meal: preparedMeal,
      },
    };

    store.dispatch({
      type: ACTIONS.HOTELS.CHANGE_HAS_BREAKFAST,
      payload: updatedHotelRates,
    });
  };

  changeFreeCancellationDate = (roomIndex, value) => {
    const { hotelRates } = this.get();
    const updatedHotelRates = [...hotelRates];

    updatedHotelRates[roomIndex] = {
      ...updatedHotelRates[roomIndex],
      amenities: {
        ...updatedHotelRates[roomIndex].amenities,
        freeCancellationDate: value,
      },
    };

    store.dispatch({
      type: ACTIONS.HOTELS.CHANGE_FREE_CANCELLATION_DATE,
      payload: updatedHotelRates,
    });
  };

  changeCancellationDateWithFee = (value) => {
    store.dispatch({
      type: ACTIONS.HOTELS.CHANGE_CANCELLATION_DATE_WITH_FEE,
      payload: value,
    });
  };

  changePrices = (roomIndex, fieldName, value) => {
    const { hotelRates } = this.get();
    const updatedHotelRates = [...hotelRates];

    updatedHotelRates[roomIndex] = {
      ...updatedHotelRates[roomIndex],
      price: {
        ...updatedHotelRates[roomIndex].price,
        [fieldName]: value,
      },
    };

    store.dispatch({
      type: ACTIONS.HOTELS.CHANGE_PRICES,
      payload: updatedHotelRates,
    });
  };

  changeCancellationPrices = (roomIndex, fieldName, value) => {
    const { hotelRates } = this.get();
    const updatedHotelRates = [...hotelRates];

    updatedHotelRates[roomIndex] = {
      ...updatedHotelRates[roomIndex],
      amenities: {
        ...updatedHotelRates[roomIndex].amenities,
        cancellationPenalties: updatedHotelRates[roomIndex].amenities.cancellationPenalties.map((penalty) => ({
          ...penalty,
          [fieldName]: value,
        })),
      },
    };

    store.dispatch({
      type: ACTIONS.HOTELS.CHANGE_CANCELLATION_PRICES,
      payload: updatedHotelRates,
    });
  };

  changeIntercomLink = (value) => {
    store.dispatch({
      type: ACTIONS.HOTELS.CHANGE_INTERCOM_LINK,
      payload: value,
    });

    const chatId = extractIntercomChatId(value);
    this.setIntercomChatId(chatId);
  };

  setIntercomChatId = (value) => {
    if (!value) {
      return null;
    }

    store.dispatch({
      type: ACTIONS.HOTELS.SET_INTERCOM_ID,
      payload: value,
    });
  };

  setIntercomLinkForEdit = (chatId) => {
    if (!chatId) {
      store.dispatch({
        type: ACTIONS.HOTELS.CHANGE_INTERCOM_LINK,
        payload: '',
      });

      return;
    }

    const link = chatId.toString().length <= CHAT_ID_LENGTH ? INTERCOM_LINK.PROD : INTERCOM_LINK.TEST;

    store.dispatch({
      type: ACTIONS.HOTELS.CHANGE_INTERCOM_LINK,
      payload: link(chatId),
    });

    this.setIntercomChatId(chatId);
  };

  changeHotelRateId = (roomIndex, id) => {
    const { hotelRates } = this.get();
    const updatedHotelRates = [...hotelRates];

    updatedHotelRates[roomIndex] = {
      ...updatedHotelRates[roomIndex],
      id,
    };

    store.dispatch({
      type: ACTIONS.HOTELS.CHANGE_HOTEL_RATE_ID,
      payload: updatedHotelRates,
    });
  };

  changeVatAmount = (value) => {
    store.dispatch({
      type: ACTIONS.HOTELS.CHANGE_VAT_AMOUNT,
      payload: value,
    });
  };

  changeCheckinDate = (roomIndex, value) => {
    const { hotelRates } = this.get();
    const updatedHotelRates = [...hotelRates];

    updatedHotelRates[roomIndex] = {
      ...updatedHotelRates[roomIndex],
      checkinDate: value,
    };

    store.dispatch({
      type: ACTIONS.HOTELS.CHANGE_CHECKIN_DATE,
      payload: updatedHotelRates,
    });
  };

  changeCheckinTime = (roomIndex, value) => {
    const { hotelRates } = this.get();

    const updatedHotelRates = hotelRates.map((hotelRate, i) => ([ALL_ROOM_INDEX, i].includes(roomIndex)
      ? { ...hotelRate, checkinTime: value }
      : hotelRate));

    store.dispatch({
      type: ACTIONS.HOTELS.CHANGE_CHECKIN_TIME,
      payload: updatedHotelRates,
    });
  };

  changeCheckoutDate = (roomIndex, value) => {
    const { hotelRates } = this.get();
    const updatedHotelRates = [...hotelRates];

    updatedHotelRates[roomIndex] = {
      ...updatedHotelRates[roomIndex],
      checkoutDate: value,
    };

    store.dispatch({
      type: ACTIONS.HOTELS.CHANGE_CHECKOUT_DATE,
      payload: updatedHotelRates,
    });
  };

  changeCheckoutTime = (roomIndex, value) => {
    const { hotelRates } = this.get();

    const updatedHotelRates = hotelRates.map((hotelRate, i) => ([ALL_ROOM_INDEX, i].includes(roomIndex)
      ? { ...hotelRate, checkoutTime: value }
      : hotelRate));

    store.dispatch({
      type: ACTIONS.HOTELS.CHANGE_CHECKOUT_TIME,
      payload: updatedHotelRates,
    });
  };

  changeSelectedHotel = (value) => {
    if (!value) {
      this.setHotelId(0);
    }

    store.dispatch({
      type: ACTIONS.HOTELS.CHANGE_SELECTED_HOTEL,
      payload: value,
    });
  };

  changeWorksWithVat = (value) => {
    store.dispatch({
      type: ACTIONS.HOTELS.CHANGE_WORKS_WITH_VAT,
      payload: value,
    });
  };

  changePriceInclVat = (value) => {
    store.dispatch({
      type: ACTIONS.HOTELS.CHANGE_PRICE_INC_VAT,
      payload: value,
    });
  };

  setSelectedRooms = (roomIndex, item) => {
    const { hotelRates } = this.get();

    const updatedHotelRates = hotelRates.map((hotelRate, i) => ([ALL_ROOM_INDEX, i].includes(roomIndex)
      ? { ...hotelRate, roomCategory: item }
      : hotelRate));

    store.dispatch({
      type: ACTIONS.HOTELS.CHANGE_SELECTED_ROOMS,
      payload: updatedHotelRates,
    });
  };

  changeSelectedRooms = (roomIndex, value) => {
    const { hotelRates } = this.get();

    const updatedHotelRates = hotelRates.map((hotelRate, i) => ([ALL_ROOM_INDEX, i].includes(roomIndex)
      ? { ...hotelRate, roomCategory: value }
      : hotelRate));

    store.dispatch({
      type: ACTIONS.HOTELS.CHANGE_SELECTED_ROOMS,
      payload: updatedHotelRates,
    });
  };

  changeAdditionalEmail = (value) => {
    store.dispatch({
      type: ACTIONS.HOTELS.CHANGE_ADDITIONAL_EMAIL,
      payload: value,
    });
  };

  setHotelContacts = (phone, id) => store.dispatch({
    type: ACTIONS.HOTELS.GET_HOTEL_INFO,
    payload: { phone, hotelId: id },
  });

  getHotelInfo = async (id) => {
    const { noContacts: { noEmail, noPhone } } = this.get();

    try {
      if (noEmail && noPhone) {
        store.dispatch({
          type: ACTIONS.HOTELS.SET_NO_CONTACTS,
          noEmail: false,
          noPhone: false,
        });
      }

      const res = await this.getHotel(id);

      if (!res) {
        this.setHotelId(0);

        return [];
      }

      if (res) {
        const {
          Email, Phone, RoomGroups, Id, CheckinTime, CheckoutTime,
        } = res.Static;

        store.dispatch({
          type: ACTIONS.HOTELS.SET_HOTEL,
          payload: res.Static,
        });

        this.setHotelContacts(Phone, Id);
        this.setContacts(CONTACTS_FIELDS.EMAIL, Email);

        this.changeCheckinTime(ALL_ROOM_INDEX, CheckinTime.replace(':00:00', ':00'));

        this.changeCheckoutTime(ALL_ROOM_INDEX, CheckoutTime.replace(':00:00', ':00'));

        if (!Email && !Phone) {
          store.dispatch({ type: ACTIONS.HOTELS.SET_NO_CONTACTS });
        }

        this.setRoomTypes(RoomGroups);

        await this.getRoomTypes();

        return res.Static;
      }
    } catch (error) {
      store.dispatch({ type: ACTIONS.HOTELS.SET_NO_CONTACTS });
    }
  };

  getHotelContacts = async (hotelId) => {
    try {
      const res = await this.getHotel(hotelId);

      if (!res) return null;

      const { Phone, Id } = res.Static;

      this.setHotelContacts(Phone, Id);
    } catch (error) {
      store.dispatch({ type: ACTIONS.HOTELS.SET_NO_CONTACTS });
    }
  };

  getSavedRequests = async (companyId) => {
    store.dispatch({
      type: ACTIONS.HOTELS.UPDATE_LOADING,
      payload: true,
    });

    try {
      const res = await Api.Trip.getHotelSavedRequests(companyId);

      const sortedRequests = !res ? [] : res.sort((a, b) => b.id - a.id);

      store.dispatch({
        type: ACTIONS.HOTELS.GET_SAVED_REQUESTS,
        payload: sortedRequests,
      });

      store.dispatch({
        type: ACTIONS.HOTELS.UPDATE_LOADING,
        payload: false,
      });
    } catch (err) {
      store.dispatch({
        type: ACTIONS.HOTELS.GET_SAVED_REQUESTS,
        payload: [],
      });

      store.dispatch({
        type: ACTIONS.HOTELS.UPDATE_LOADING,
        payload: false,
      });
    }
  };

  requestHotelInfo = async (value) => {
    const id = value && typeof value === 'object' ? value.Id : value;

    await this.getHotelInfo(id);
    await this.getTravellineRates(id);
  };

  getRoomTypes = () => {
    const { roomTypes } = this.get();

    if (!roomTypes || roomTypes.length === 0) {
      this.setRoomTypes('');

      this.changeSelectedRooms(ALL_ROOM_INDEX, '');

      return;
    }

    const roomsList = roomTypes.map(({ Name }, index) => ({
      label: Name,
      value: String(index),
    }));

    this.setRoomTypes(roomsList);
  };

  setRoomType = (value) => {
    store.dispatch({
      type: ACTIONS.HOTELS.SET_ROOM_TYPE,
      payload: value,
    });
  };

  setAdditionalComment = (value) => {
    store.dispatch({
      type: ACTIONS.HOTELS.SET_ADDITIONAL_COMMENT,
      payload: value,
    });
  };

  setCustomersList = (customers) => {
    if (!customers || customers.length === 0) {
      store.dispatch({
        type: ACTIONS.HOTELS.SET_CUSTOMERS_LIST,
        payload: [],
      });

      return;
    }

    const preparedCustomersList = customers.map(({
      Name,
      Patronymic,
      Surname,
      Id,
    }) => ({
      label: `${Surname} ${Name} ${Patronymic}`,
      value: Id,
      selected: false,
    }));

    store.dispatch({
      type: ACTIONS.HOTELS.SET_CUSTOMERS_LIST,
      payload: preparedCustomersList,
    });
  };

  setSelectedCustomers = (values, roomIndex) => {
    const { hotelRates } = this.get();
    const updatedHotelRates = [...hotelRates];

    updatedHotelRates[roomIndex] = {
      ...updatedHotelRates[roomIndex],
      employees: values,
    };

    store.dispatch({
      type: ACTIONS.HOTELS.SET_SELECTED_CUSTOMERS,
      payload: updatedHotelRates,
    });
  };

  getCustomerInfo = async (roomIndex, employeeId) => {
    try {
      const employee = await Api.Trip.getEmployeeDataByID(employeeId);
      const { hotelRates } = this.get();
      const updatedHotelRates = [...hotelRates];

      updatedHotelRates[roomIndex] = {
        ...updatedHotelRates[roomIndex],
        customers: [employee],
      };

      store.dispatch({
        type: ACTIONS.HOTELS.SET_CUSTOMER_INFO,
        payload: updatedHotelRates,
      });
    } catch (e) {
      store.dispatch({
        type: ACTIONS.HOTELS.SET_CUSTOMER_INFO,
        payload: hotelRates,
      });
    }
  };

  setContacts = (fieldName, value) => {
    store.dispatch({
      type: ACTIONS.HOTELS.SET_CONTACTS,
      payload: {
        fieldName,
        value,
      },
    });
  };

  setRoomTypes = (value) => {
    store.dispatch({
      type: ACTIONS.HOTELS.GET_ROOMS_TYPES,
      payload: value,
    });
  };

  setHotelId = (value) => {
    if (value === 0) {
      this.setContacts(CONTACTS_FIELDS.EMAIL, '');
      this.setContacts(CONTACTS_FIELDS.PHONE, '');
      this.changeCheckinTime(ALL_ROOM_INDEX, '');
      this.changeCheckoutTime(ALL_ROOM_INDEX, '');
      this.setSelectedRooms(ALL_ROOM_INDEX, '');
      this.setRoomTypes([]);
      this.setVatValues(false, false);
    }

    store.dispatch({
      type: ACTIONS.HOTELS.GET_HOTEL_INFO,
      payload: { Id: value },
    });
  };

  changeHotelInput = async (value) => {
    this.changeSelectedHotel(value);
    this.changeField(value, FIELDS.SEARCH.VALUE);

    const suggest = await this.autocompleteHotel(value);
    this.changeField(suggest, FIELDS.SEARCH.SUGGEST);
  };

  changeVatValues = ({ value }) => {
    if (value === 0) {
      this.changeWorksWithVat(false);
      this.changePriceInclVat(false);
    }

    if (value === 1) {
      this.changeWorksWithVat(true);
      this.changePriceInclVat(true);
    }

    if (value === VAT_RATES_AS_NUMBERS.FIVE || value === VAT_RATES_AS_NUMBERS.SEVEN) {
      this.changeWorksWithVat(false);
      this.changePriceInclVat(false);
    }

    if (value === VAT_RATES_AS_NUMBERS.TWENTY) {
      this.changeWorksWithVat(true);
      this.changePriceInclVat(false);
    }

    this.changeVatAmount(value);
  };

  changeCheckDates = (index, field, value) => {
    if (field === DATE_FIELDS.CHECKIN_DATE) {
      this.changeCheckinDate(index, value);
    }

    if (field === DATE_FIELDS.CHECKOUT_DATE) {
      this.changeCheckoutDate(index, value);
    }

    if (field === DATE_FIELDS.FREE_CANCELLATION_DATE) {
      this.changeFreeCancellationDate(index, value);
    }

    if (field === DATE_FIELDS.CANCELLATION_DATE_WITH_FEE) {
      this.changeCancellationDateWithFee(index, value);
    }
  };

  changeIsEditing = async (value) => {
    await store.dispatch({
      type: ACTIONS.HOTELS.CHANGE_IS_EDITING,
      payload: value,
    });
  };

  getSavedRequest = async (requestId, accountId) => {
    try {
      const request = await Api.Trip.getSavedRequest(requestId);

      if (request) {
        store.dispatch({
          type: ACTIONS.HOTELS.GET_EDITED_REQUEST,
          payload: request,
        });

        await this.getProjects(accountId);
        await this.getUserAnalytics(accountId);
        await this.setSavedRequest(request);
      }
    } catch (err) {
      store.dispatch({
        type: ACTIONS.HOTELS.GET_EDITED_REQUEST,
        payload: {},
      });
    }
  };

  sendRequestToHotel = async (companyId, accountId) => {
    const {
      savedRequests, editedRequest, isEditing, customTemplate: { file },
    } = this.get();
    const data = this.prepareDataForHotelRequest(companyId, accountId);
    const existingRequest = savedRequests.find(({ id }) => id === editedRequest.id);

    try {
      if (isEditing && editedRequest && existingRequest) {
        const { id } = editedRequest;

        await Api.Trip.saveEditedHotelBookRequest(data);
        await this.sendCustomTemplate(id, file);
        const emailSubj = await Api.Trip.sendRequestToHotel(id);
        await this.changeIsEditing(false);
        await this.getSavedRequests(companyId);

        this.setLetterSubject(emailSubj.subject);
        this.reset();

        return emailSubj.subject;
      }

      if (!existingRequest || !isEditing || !editedRequest) {
        const newRequest = await this.saveHotelBookRequest(companyId, accountId);
        const emailSubj = await Api.Trip.sendRequestToHotel(newRequest.id);
        await this.getSavedRequests(companyId);

        this.setLetterSubject(emailSubj.subject);
        this.reset();

        return emailSubj.subject;
      }
    } catch (error) {
      await this.getSavedRequests(companyId);

      this.reset();

      return error;
    }
  };

  changeIsSelfEmployed = (value) => {
    store.dispatch({
      type: ACTIONS.HOTELS.SET_IS_SELF_EMPLOYED,
      payload: value,
    });
  };

  setCartId = (value) => {
    store.dispatch({
      type: ACTIONS.HOTELS.SET_CART_ID,
      payload: Number(value),
    });
  };

  setSavedRequest = async (request) => {
    const {
      templateId,
      status,
      hotelInfo: {
        hotelId,
        emails,
        phone,
        hotelName,
        worksWithVat,
        priceInclVat,
        vatRate,
        isSelfEmployed,
      },
      hotelRates,
      conversationId,
      cartId,
      letterSubject,
      additionalComment,
      userAnalytics,
    } = request;

    this.clearRooms();

    this.changeSelectedHotel(hotelName);
    this.setHotelContacts(phone, hotelId);
    this.changeField(hotelName, FIELDS.SEARCH.VALUE);
    this.setVatValues(worksWithVat, priceInclVat, vatRate);
    this.setIntercomLinkForEdit(conversationId);
    this.setContacts(CONTACTS_FIELDS.EMAIL, emails[0]);
    this.setContacts(CONTACTS_FIELDS.PHONE, phone);
    this.setAdditionalComment(additionalComment);
    this.changeIsSelfEmployed(isSelfEmployed);
    this.setLetterSubject(letterSubject);
    this.setTemplateId(templateId);
    this.setCustomRequestAnalytics(userAnalytics);

    if (emails.length > 1) {
      this.setContacts(CONTACTS_FIELDS.ADDITIONAL_EMAIL, emails[1]);
    }

    hotelRates.map(({
      id,
      price,
      amenities,
      checkinTime,
      checkoutTime,
      employees,
      roomCategory,
      earlyCheckin,
      lateCheckout,
      departmentId,
      projectId,
      userAnalytics,
    }, i) => {
      this.addRoom();
      const dailyPrice = Number(price.dailyPrice);
      const earlyCheckInPrice = Number(price.earlyCheckIn);
      const lateCheckoutPrice = Number(price.lateCheckOut);
      const freeCancelDate = amenities.hasFreeCancellation && amenities.freeCancellationDate
        ? dateParseZone(amenities.freeCancellationDate)
        : null;
      const baseCancellationPenalty = freeCancelDate
        ? Number(amenities.cancellationPenalties[0].Base)
        : null;
      const totalCancellationPenalty = freeCancelDate
        ? Number(amenities.cancellationPenalties[0].Total)
        : null;
      const additional = freeCancelDate
        ? amenities.cancellationPenalties[0].Additional
        : null;

      this.changeHotelRateId(i, id);
      this.changePrices(i, PRICE_VALUES.dailyPrice, dailyPrice);
      this.changePrices(i, PRICE_VALUES.earlyCheckin, earlyCheckInPrice);
      this.changePrices(i, PRICE_VALUES.lateCheckout, lateCheckoutPrice);
      this.changeCancellationPrices(i, CANCELLATION_PENALTIES.base, baseCancellationPenalty);
      this.changeCancellationPrices(i, CANCELLATION_PENALTIES.total, totalCancellationPenalty);
      this.changeCancellationPrices(i, CANCELLATION_PENALTIES.additional, additional);
      this.changeCheckinDate(i, dateParseZone(checkinTime));
      this.changeCheckoutDate(i, dateParseZone(checkoutTime));
      this.changeCheckinTime(i, formatDate(checkinTime, DEFAULTTIME));
      this.changeCheckoutTime(i, formatDate(checkoutTime, DEFAULTTIME));
      this.setSelectedCustomers(employees, i);
      this.setSelectedRooms(i, roomCategory);
      this.changeHasEarlyCheckin(i, earlyCheckin);
      this.changeHasLateCheckout(i, lateCheckout);
      this.changeHasBreakfast(i, amenities.hasMeal);
      this.changeHasFreeCancellation(i, amenities.hasFreeCancellation);
      this.changeFreeCancellationDate(i, freeCancelDate);
      this.setDepartmentFromRequest(i, departmentId);
      this.setProjectFromRequest(i, projectId);
      this.setCommission(price.commission, i);
      this.setRoomAnalytics(i, userAnalytics);
    });

    this.setRequestUserId('');

    switch (status) {
      case REQUEST_STATUS_LABELS.Pending:
        this.changeRequestStatus(REQUEST_STATUSES[0]);
        break;
      case REQUEST_STATUS_LABELS.Confirmed:
        this.changeRequestStatus(REQUEST_STATUSES[1]);
        break;
      default:
        this.changeRequestStatus(REQUEST_STATUSES[0]);
    }

    if (hotelId !== 0) {
      this.setHotelId(hotelId);
    }

    if (emails[1]) {
      this.changeAdditionalEmail(true);
      this.setContacts(CONTACTS_FIELDS.ADDITIONAL_EMAIL, emails[1]);
    }

    if (!emails[1]) {
      this.changeAdditionalEmail(false);
      this.setContacts(CONTACTS_FIELDS.ADDITIONAL_EMAIL, '');
    }

    if (cartId) {
      this.setCartId(cartId);
      await this.getHotelContacts(hotelId);
    }
  };

  uploadCustomTemplate = async (file) => {
    await store.dispatch({
      type: ACTIONS.HOTELS.UPLOAD_CUSTOM_TEMPLATE_REQUEST,
      file,
    });
  };

  sendCustomTemplate = async (requestId, file) => {
    try {
      await Api.Trip.sendCustomTemplate(requestId, file);

      this.resetCustomTemplate();
    } catch (e) {
      store.dispatch({ type: ACTIONS.HOTELS.UPLOAD_TEMPLATE_FAILED });

      this.resetCustomTemplate();
    }
  };

  resetCustomTemplate = () => {
    store.dispatch({ type: ACTIONS.HOTELS.RESET_CUSTOM_TEMPLATE_UPLOAD });
  };

  changeRequestStatus = ({ value }) => {
    store.dispatch({
      type: ACTIONS.HOTELS.CHANGE_REQUEST_STATUS,
      payload: value,
    });
  };

  setSelectedDepartment = ({ id }, roomIndex) => {
    const { hotelRates } = this.get();
    const updatedHotelRates = [...hotelRates];

    updatedHotelRates[roomIndex] = {
      ...updatedHotelRates[roomIndex],
      departmentId: id,
    };

    store.dispatch({
      type: ACTIONS.HOTELS.SET_SELECTED_DEPARTMENT,
      payload: updatedHotelRates,
    });
  };

  setDepartmentFromRequest = (roomIndex, departmentId) => {
    const { hotelRates } = this.get();
    const updatedHotelRates = [...hotelRates];

    updatedHotelRates[roomIndex] = {
      ...updatedHotelRates[roomIndex],
      departmentId,
    };

    store.dispatch({
      type: ACTIONS.HOTELS.SET_SELECTED_DEPARTMENT,
      payload: updatedHotelRates,
    });
  };

  setProjectId = ({ id }, roomIndex) => {
    const { hotelRates } = this.get();
    const updatedHotelRates = [...hotelRates];

    updatedHotelRates[roomIndex] = {
      ...updatedHotelRates[roomIndex],
      projectId: id,
    };

    store.dispatch({
      type: ACTIONS.HOTELS.SET_CUSTOMER_PROJECT_ID,
      payload: updatedHotelRates,
    });
  };

  setProjectFromRequest = (roomIndex, projectId) => {
    const { hotelRates } = this.get();
    const updatedHotelRates = [...hotelRates];

    updatedHotelRates[roomIndex] = {
      ...updatedHotelRates[roomIndex],
      projectId,
    };

    store.dispatch({
      type: ACTIONS.HOTELS.SET_CUSTOMER_PROJECT_ID,
      payload: updatedHotelRates,
    });
  };

  setLetterSubject = (value) => {
    store.dispatch({
      type: ACTIONS.HOTELS.SET_LETTER_SUBJECT,
      payload: value,
    });
  };

  setUsers = (value) => {
    store.dispatch({
      type: ACTIONS.HOTELS.SET_USERS,
      payload: value,
    });
  };

  getRequestUsers = async (companyId) => {
    try {
      const users = await Api.Trip.getUsers(companyId);

      if (!users) {
        return this.setUsers([]);
      }

      const mappedUsers = users.map(({ Id, UserName }) => ({
        label: UserName,
        value: Id,
      }));

      this.setUsers(mappedUsers);
    } catch (error) {
      this.setUsers([]);
    }
  };

  setRequestUserId = (userId) => {
    store.dispatch({
      type: ACTIONS.HOTELS.SET_REQUEST_USER_ID,
      payload: userId,
    });
  };

  getMandatoryProject = async (accountId) => {
    try {
      const res = await Api.Trip.getMandatoryProject(accountId);

      store.dispatch({
        type: ACTIONS.HOTELS.GET_MANDATORY_PROJECT,
        payload: res,
      });
    } catch (err) {
      store.dispatch({
        type: ACTIONS.HOTELS.GET_MANDATORY_PROJECT,
        MandatoryProject: [],
      });
    }
  };

  getProjects = async (accountId) => {
    try {
      const projects = await Api.Trip.getProjects(accountId);

      const preparedProjects = projects.map(({
        Name,
        Id,
      }) => ({
        name: Name,
        id: Id,
      }));

      if (preparedProjects) {
        store.dispatch({
          type: ACTIONS.HOTELS.SET_CUSTOMER_PROJECTS,
          payload: preparedProjects,
        });
      }
    } catch (e) {
      store.dispatch({
        type: ACTIONS.HOTELS.SET_CUSTOMER_PROJECTS,
        payload: [],
      });
    }
  };

  getUserAnalytics = async (accountId) => {
    try {
      const analytic = await Api.CustomAnalytics.getAnalyticsWithoutValues(accountId);

      if (analytic) {
        const preparedAnalytics = analytic.map(({
          Id, AccountId, Name, Values, ApplyToTrip, Required,
        }) => ({
          Id,
          AccountId,
          Name,
          ApplyToTrip,
          Values,
          Required,
        }));

        store.dispatch({
          type: ACTIONS.HOTELS.GET_ACCOUNT_ANALYTICS,
          payload: preparedAnalytics,
        });
      }
    } catch (error) {
      store.dispatch({
        type: ACTIONS.HOTELS.GET_ACCOUNT_ANALYTICS,
        payload: [],
      });
    }
  };

  getSelectedCustomersByIds = async () => {
    const { hotelRates } = this.get();

    const customers = hotelRates.map(({ employees }) => employees).flat();

    const employees = await Promise.all(customers.map(async (employee) => {
      try {
        return await Api.Trip.getEmployeeDataByID(employee);
      } catch (error) {
        return [];
      }
    }));

    if (!employees) {
      store.dispatch({
        type: ACTIONS.HOTELS.SET_EMPLOYEE_LIST,
        payload: [],
      });
    }

    store.dispatch({
      type: ACTIONS.HOTELS.SET_EMPLOYEE_LIST,
      payload: employees,
    });
  };

  setCustomRoomAnalytics = (roomIndex, id, value, customAnalytics) => {
    const { hotelRates } = this.get();
    const updatedHotelRates = [...hotelRates];

    if (value.id === 0) {
      customAnalytics.removeHotelSelectedAnalytic(id, roomIndex);
    } else {
      customAnalytics.updateHotelSelectedAnalytic(id, value.id, roomIndex);
    }

    updatedHotelRates[roomIndex] = {
      ...updatedHotelRates[roomIndex],
      userAnalytics: Object.values(customAnalytics.hotelSelectedAnalytics[roomIndex]),
    };

    store.dispatch({
      type: ACTIONS.HOTELS.SET_ACCOUNT_ANALYTICS,
      payload: updatedHotelRates,
    });
  };

  setRoomAnalytics = (roomIndex, userAnalytics) => {
    const { hotelRates } = this.get();
    const newSelectedAnalytics = userAnalytics;
    const updatedHotelRates = [...hotelRates];

    updatedHotelRates[roomIndex] = {
      ...updatedHotelRates[roomIndex],
      userAnalytics: newSelectedAnalytics,
    };

    store.dispatch({
      type: ACTIONS.HOTELS.SET_ACCOUNT_ANALYTICS,
      payload: updatedHotelRates,
    });
  };

  createNewTripFromRequest = async (companyId, accountId) => {
    this.setNewTripFailureState(false);
    this.setNewTripSuccess(false);
    this.setNoHotel(false);
    this.updateBtnRequest(true);

    try {
      const {
        employeesList,
        prices: { breakfastPrice },
        intercomChatId,
        hotelInfo: {
          hotelId,
          phone,
          vatRate: hotelVatRate,
          worksWithVat,
          priceInclVat,
        },
        hotelRates,
        reservationDate,
        editedRequest: {
          id,
          cartId,
        },
        selectedHotel,
        tripId,
        requestUserId,
        selectedRequestAnalytics,
      } = this.get();

      const hotel = await Api.Trip.getHotelDetails(hotelId);

      if (!hotel) {
        this.setNoHotel(true);
        this.setNewTripFailureState(true);
        this.updateBtnRequest(false);

        return;
      }

      const allUsers = await Api.Trip.getUsers(companyId);

      const isHasVat = worksWithVat || priceInclVat;
      const hasInternalVat = worksWithVat || priceInclVat || (hotelVatRate === 5 || hotelVatRate === 7);
      const isTemplateForDirect = worksWithVat && !priceInclVat;
      const provider = isTemplateForDirect ? HOTEL_PROVIDER_VALUE.direct : HOTEL_PROVIDER_VALUE.verdeho;

      const users = allUsers.map(({ Email }) => Email);

      const {
        Address = '',
        City = '',
        Name = '',
        Phone = '',
        Stars = 0,
        RegionId = 0,
        ClassificatorId = 0,
      } = hotel || {};

      const customAnalytics = Object.values(selectedRequestAnalytics);

      const preparedVersions = await Promise.all(hotelRates.map(async ({
        earlyCheckin,
        lateCheckout,
        checkinDate,
        checkoutDate,
        price,
        amenities,
        employees,
        roomCategory,
        departmentId,
        projectId,
        userAnalytics,
      }) => {
        const smartwayCommission = Number(price.commission) || 0;

        const daysDiff = diffDays(
          momentSetHoursAndMinutes(checkinDate, 0, 0), momentSetHoursAndMinutes(checkoutDate, 0, 0),
        ) || 1;

        const dailyCost = Number(price.dailyPrice) || 0;
        const nightsCost = Number(dailyCost) * daysDiff || 0;
        const basePriceWithECLC = nightsCost + Number(price.earlyCheckin) + Number(price.lateCheckout);

        const isPriceExcludingVat = (worksWithVat && !priceInclVat) || hotelVatRate === 7 || hotelVatRate === 5;
        const vatRate = isPriceExcludingVat ? hotelVatRate : 0;
        const vatAmount = await Api.Trip.getVatAmount({
          Rate: Number(vatRate),
          Amount: basePriceWithECLC,
        });

        const selectedMealType = amenities.hasMeal ? MEAL_LIST_TYPE[0] : MEAL_LIST_TYPE[8];

        const customAnalytics = Object.values(userAnalytics);

        const fullCheckInDate = earlyCheckin ? checkinDate : momentSetHoursAndMinutes(checkinDate, 0, 0);
        const fullCheckOutDate = lateCheckout ? checkoutDate : momentSetHoursAndMinutes(checkoutDate, 0, 0);

        const freeCancelDate = amenities.hasFreeCancellation && amenities.freeCancellationDate
          ? formatDate(amenities.freeCancellationDate, UTC_FULLTIME)
          : null;
        const cancellationDateWithoutFee = amenities.hasFreeCancellation && freeCancelDate
          ? hoursSubtract(amenities.freeCancellationDate, HOURS_FOR_SUBTRACT)
          : null;
        const cancellationDateWithFee = amenities.hasFreeCancellation && cancellationDateWithoutFee
          ? dateAddMinutes(cancellationDateWithoutFee, MINUTES_TO_ADD)
          : null;

        const freeCancellationForClient = cancellationDateWithFee
          ? [
            {
              From: formatDate(cancellationDateWithFee, UTC_FULLTIME),
              Total: amenities.cancellationPenalties[0].total || 0,
              Base: amenities.cancellationPenalties[0].base || 0,
              Additional: amenities.cancellationPenalties[0].additional || false,
            },
          ]
          : [];

        const freeCancellationForSmartway = freeCancelDate
          ? [
            {
              From: freeCancelDate,
              Total: amenities.cancellationPenalties[0].total || 0,
              Base: amenities.cancellationPenalties[0].base || 0,
              BaseCurrency: '',
              MFInCurrency: '',
              MFRubPenalties: '',
              ProviderPrice: '',
              Additional: amenities.cancellationPenalties[0].additional || false,
            },
          ]
          : [];

        const bookInfo = {
          CheckinDate: formatDate(fullCheckInDate, FULLTIME),
          CheckoutDate: formatDate(fullCheckOutDate, FULLTIME),
          NightsCount: daysDiff,
          NightsPrice: price.dailyPrice || 0,
          EmployeeIds: employees,
          GuestCount: employees.length,
          Hotel: {
            Address: Address || '',
            CheckinTime: formatDate(checkinDate, DEFAULTTIME),
            CheckoutTime: formatDate(checkoutDate, DEFAULTTIME),
            City: City || '',
            Name: Name || selectedHotel,
            Phone: Phone || phone,
            Stars: Stars || 0,
            ClassificatorId: ClassificatorId || 0,
            RegionId: RegionId || 0,
          },
          Price: 0,
          ReservationDate: reservationDate,
          ReservationNumber: intercomChatId || 0,
          Room: {
            Category: roomCategory,
            FreeCancellation: cancellationDateWithoutFee ? formatDate(cancellationDateWithoutFee, UTC_FULLTIME) : null,
            CancellationPenalties: freeCancellationForClient,
          },
          ProviderName: provider,
          Breakfast: amenities.hasMeal,
          BreakfastName: '',
          ServiceType: SERVICETYPE.HOTEL,
          CustomCheckInDate: earlyCheckin,
          CustomCheckOutDate: lateCheckout,
          CancellationPenalties: freeCancellationForSmartway,
          PriceDetails: {
            Base: basePriceWithECLC || 0,
            commission: smartwayCommission,
            hasVat: isHasVat,
            EarlyCheckIn: Number(price.earlyCheckin) || 0,
            LateCheckOut: Number(price.lateCheckout) || 0,
          },
        };

        const preparedVatDetails = !hasInternalVat
          ? []
          : [
            {
              Rate: vatRate,
              Amount: vatAmount.Vat,
            },
          ];

        return {
          OrderTripItemId: 0,
          OrderTripId: tripId || 0,
          CompanyId: companyId || 0,
          ProjectId: projectId || 0,
          DepartmentId: departmentId || 0,
          Status: 0,
          ServiceType: SERVICETYPE.HOTEL,
          PriceDetails: {
            Base: Number(price.dailyPrice) || 0,
            commission: smartwayCommission,
            hasVat: isHasVat,
            EarlyCheckIn: Number(price.earlyCheckin) || 0,
            LateCheckOut: Number(price.lateCheckout) || 0,
          },
          InternalVat: {
            VatReady: true,
            VatInfo: {
              HasVat: hasInternalVat,
              RackRate: 0,
              VatDetails: preparedVatDetails,
            },
          },
          Contact: '',
          CurrencyData: null,
          Meal: {
            Include: selectedMealType.value,
            Name: selectedMealType.label,
            Category: selectedMealType.value,
            Additional: false,
            Price: breakfastPrice || 0,
          },
          UserAnalytics: customAnalytics || [],
          NightsPrice: nightsCost,
          NightsCount: daysDiff,
          EmployeesList: employeesList,
          ProviderInn: '',
          ReportAmount: null,
          JsonData: JSON.stringify(bookInfo),
        };
      }));

      const newTrip = {
        UserId: requestUserId,
        Name: City || '',
        Users: users,
        Versions: preparedVersions,
        UserAnalytics: customAnalytics || [],
      };

      await this.createNewTrip(companyId, newTrip, id, accountId, cartId);

      this.updateBtnRequest(false);
      this.reset();
    } catch (err) {
      this.updateBtnRequest(false);

      return this.setNewTripFailureState(true);
    }
  };

  createNewTrip = async (companyId, newTrip, requestId, accountId, cartId) => {
    const { tripId } = await Api.Trip.create(companyId, newTrip, '');

    if (tripId) {
      this.setTripId(tripId);
      this.setNewTripSuccess(true);

      await this.saveEditedRequest(companyId, accountId);
      await Api.IntercomNote.sendTicketToIntercom(requestId);
      await this.deleteCartFromCartsAndNotes(cartId);

      this.moveToTrip(companyId, accountId, tripId);
    }
  };

  moveToTrip = (companyId, accountId, tripId) => {
    const path = `/account/${accountId}/company/${companyId}/trip/${tripId}`;

    window.open(path, '_blank');
  };

  deleteCartFromCartsAndNotes = async (cartId) => {
    try {
      await Api.Cart.deleteCart(cartId);
    } catch (err) {
      return null;
    }
  };

  setNewTripSuccess = (value) => {
    store.dispatch({
      type: ACTIONS.HOTELS.SET_CREATE_TRIP_SUCCESS,
      payload: value,
    });
  };

  setNewTripFailureState = (value) => {
    store.dispatch({
      type: ACTIONS.HOTELS.SET_CREATE_TRIP_FAILURE,
      payload: value,
    });
  };

  setNoHotel = (value) => {
    store.dispatch({
      type: ACTIONS.HOTELS.SET_NO_HOTEL_FOUND,
      payload: value,
    });
  };

  setTemplateId = (value) => {
    store.dispatch({
      type: ACTIONS.HOTELS.SET_TEMPLATE_ID,
      payload: value,
    });
  };

  setTripId = (id) => {
    store.dispatch({
      type: ACTIONS.HOTELS.SET_TRIP_ID,
      payload: id,
    });
  };

  setCommission = (value, roomIndex) => {
    const { hotelRates } = this.get();
    const updatedHotelRates = [...hotelRates];

    updatedHotelRates[roomIndex] = {
      ...updatedHotelRates[roomIndex],
      price: {
        ...updatedHotelRates[roomIndex].price,
        commission: value,
      },
    };

    store.dispatch({
      type: ACTIONS.HOTELS.SET_COMMISSION,
      payload: updatedHotelRates,
    });
  };

  changeMatchId = (field, value) => {
    store.dispatch({
      type: ACTIONS.HOTELS.CHANGE_MATCH_ID,
      payload: { field, value },
    });
  };

  validMatchIds = () => {
    const { matchProvidersId } = this.get();

    const findNullMatch = Object.keys(matchProvidersId).find((key) => !!matchProvidersId[key]);

    if (!findNullMatch) {
      store.dispatch({
        type: ACTIONS.HOTELS.ERROR_MATCH,
        payload: NO_MATCHES,
      });

      return false;
    }

    return true;
  };

  setMatchedHotels = (data) => {
    store.dispatch({
      type: ACTIONS.HOTELS.MATCHED_HOTELS,
      payload: data,
    });
  };

  setMatchesChange = () => {
    store.dispatch({ type: ACTIONS.HOTELS.IS_CHANGE_MATCHES });
  };

  checkMatchHotels = async (moveToAddHotel) => {
    const {
      matchProvidersId: {
        IdOstrovok,
        IdBronevik,
        IdAanda,
        IdAcadem,
        IdExpedia,
        IdTripcom,
      },
    } = this.get();

    store.dispatch({
      type: ACTIONS.HOTELS.LOADING_MATCHED_HOTELS,
      payload: true,
    });

    const data = {
      IdOstrovok: IdOstrovok.toString(),
      IdBronevik: IdBronevik || 0,
      IdAanda: IdAanda || 0,
      IdAcadem: IdAcadem || 0,
      IdExpedia: IdExpedia.toString(),
      IdTripcom: IdTripcom || 0,
    };

    try {
      const result = await Api.Trip.checkMatchHotels(data);

      if (!result.Exists) {
        Object.entries(data).forEach(([key, value]) => {
          this.changeField(value, `${FIELDS.MATCHING}.${key}.${FIELDS.VALUE}`);
        });

        return moveToAddHotel();
      }

      return this.setMatchedHotels(result);
    } finally {
      store.dispatch({
        type: ACTIONS.HOTELS.LOADING_MATCHED_HOTELS,
        payload: false,
      });
    }
  };

  resetMatches = () => {
    store.dispatch({ type: ACTIONS.HOTELS.RESET_MATCHES });
  };

  setComparesDialog = (value) => {
    store.dispatch({
      type: ACTIONS.HOTELS.SHOW_COMPARES_DIALOG,
      payload: value,
    });
  };

  comparesCheck = async () => {
    const {
      formData: {
        simpleFields: { CountryCode },
        matching: {
          IdAanda,
          IdAcadem,
          IdBronevik,
          IdExpedia,
          IdTripcom,
          IdGiata,
          IdOstrovok,
        },
        IdSmartway,
      },
    } = this.get();

    const data = {
      Country: CountryCode.value,
      SwId: IdSmartway,
      IdAanda: IdAanda.value || 0,
      IdAcadem: IdAcadem.value || 0,
      IdBronevik: IdBronevik.value || 0,
      IdExpedia: IdExpedia.value,
      IdTripcom: IdTripcom.value || 0,
      IdGiata: IdGiata.value || 0,
      IdOstrovok: IdOstrovok.value,
    };

    try {
      const res = await Api.Trip.comparesCheck(data);

      store.dispatch({
        type: ACTIONS.HOTELS.SET_COMPARES_ALL_MATCHES,
        payload: res,
      });

      if (res.Exists) {
        this.setComparesDialog(true);
      }

      if (res.NeedGiataId
        && Object.keys(res.GiataCompares).length === 1
      ) {
        const valueGiataId = Object.keys(res.GiataCompares)[0];

        this.changeField(valueGiataId, `${FIELDS.MATCHING}.${PROVIDERS_VALUE.ID_GIATA}.${FIELDS.VALUE}`);
      }
    } catch (err) {
      return null;
    }
  };

  getHotelsByGiataIds = async (giataIds) => {
    try {
      const res = await Api.Trip.getHotelsByGiataIds({ GiataIds: giataIds });

      store.dispatch({
        type: ACTIONS.HOTELS.SET_GIATA_MAPPING,
        payload: res,
      });
    } catch (err) {
      return null;
    }
  };

  addRoom = () => {
    store.dispatch({
      type: ACTIONS.HOTELS.ADD_NEW_ROOM,
      payload: INITIAL_ROOM_VALUES,
    });
  };

  deleteRoom = (roomIndex) => {
    const { hotelRates } = this.get();

    const updatedHotelRates = hotelRates.filter((_, index) => index !== roomIndex);

    store.dispatch({
      type: ACTIONS.HOTELS.DELETE_ROOM,
      payload: updatedHotelRates,
    });
  };

  copyRoom = (roomIndex) => {
    const { hotelRates } = this.get();

    const rateWithNoIds = lodashReplaces.omit(hotelRates[roomIndex], 'id');
    const rateWithNoIdsAndEmployees = lodashReplaces.omit(rateWithNoIds, 'employees');

    store.dispatch({
      type: ACTIONS.HOTELS.COPY_ROOM,
      payload: rateWithNoIdsAndEmployees,
    });
  };

  clearRooms = () => {
    store.dispatch({ type: ACTIONS.HOTELS.CLEAR_ROOMS });
  };

  setCustomRequestAnalytics = (userAnalytics) => {
    store.dispatch({
      type: ACTIONS.HOTELS.SET_REQUEST_ANALYTICS,
      payload: userAnalytics,
    });
  };

  selectCustomRequestAnalytics = (id, value, customAnalytics) => {
    if (value.id === 0) {
      customAnalytics.removeHotelSelectedAnalytic(id);
    } else {
      customAnalytics.updateHotelSelectedAnalytic(id, value.id);
    }

    const newSelectedRequestAnalytics = Object.values(customAnalytics.hotelSelectedAnalytics[KEYS.GENERAL_ANALYTICS]);

    store.dispatch({
      type: ACTIONS.HOTELS.SET_REQUEST_ANALYTICS,
      payload: newSelectedRequestAnalytics,
    });
  };
}

export default HotelService;
