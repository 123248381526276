import Api from '../../api';
import ACTIONS from '../../actions';

let store = null;

class FeatureFlagsService {
  constructor(InitStore) {
    store = InitStore || this.getStore('FeatureFlags');
  }

  get = () => store.getState();

  getIncomingOperationChange = () => this.get().IncomingOperationChange || false;

  getPlanFeeChange = () => this.get().PlanFeeChange || false;

  getCompanySuspend = () => this.get().CompanySuspend || false;

  subscribe = (callback) => store.subscribe(callback);

  getAllFeatureFlags = async () => {
    try {
      const payload = await Api.FeatureFlags.getAllFeatureFlags();
      store.dispatch({
        type: ACTIONS.FEATUREFLAGS.GETALLFEATUREFLAGS,
        payload,
      });
    } catch (e) {
      console.error(e);
    }
  };

  getSettingsFeatureFlags = async (accountId) => {
    const payload = await Api.FeatureFlags.getSettingsFlags(accountId);
    store.dispatch({
      type: ACTIONS.FEATUREFLAGS.GET_SETTINGS_FEATURE_FLAGS,
      payload,
    });
  };

  getXmlVisibilityFlag = () => this.get().DownloadXmlTicket || false;

  getAccessToUploadEmployees = () => this.get().AccessToUploadEmployees || false;

  getAccessToExportEmployees = () => this.get().AccessToExportEmployees || false;

  getAddCardTransactionFee = () => this.get().AddCardTransactionFee || false;

  getTariffChange = () => this.get().TariffChange || false;

  getDisplayRatesFromBooking = () => this.get().DisplayRatesFromBooking || false;

  getExpenseReports = () => this.get().ExpenseReports || false;

  getEditTripItemVersion = () => this.get().EditTripItemVersion || false;

  getHotelRestrictionChange = () => this.get().HotelRestrictionChange || false;

  getAandaContractorChange = () => this.get().AandaContractorChange || false;

  getHotelCalculateCurrency = () => this.get().HotelCurrencyCalc || false;

  getTotalAmount = () => this.get().TotalAmount || false;

  getClosingDocumentsConstructor = () => this.get().ClosingDocumentsConstructor || false;

  getTripMove = () => this.get().TripMove || false;

  getAcquiringSettingChange = () => this.get().AcquiringSettingChange || false;

  getBookingChange = () => this.get().CanSetBookingSettings || false;
}

export default FeatureFlagsService;
