import ACTIONS from '../../actions';
import Api from '../../api';

let store = null;

class EmployeeService {
  constructor() {
    store = this.getStore('Employee');
  }

  getAllEmployee = (accountId) => Api.Employee.getAllEmployee(accountId).then((res) => store.dispatch({
    type: ACTIONS.EMPLOYEE.ALL_EMPLOYEE,
    res,
  }))
    .catch(() => {
      store.dispatch({
        type: ACTIONS.EMPLOYEE.ALL_EMPLOYEE,
        res: [],
      });
    });

  getEmployee = (employeeId) => Api.Employee.getEmployee(employeeId).then((res) => store.dispatch({
    type: ACTIONS.EMPLOYEE.UPDATE,
    res,
  }));

  getProjects = (accountId) => Api.Trip.getProjects(accountId).then((res) => store.dispatch({
    type: ACTIONS.EMPLOYEE.GET_PROJECTS,
    res,
  }));

  getEmployeeTrips = async (companyId, employeeId) => {
    try {
      const res = await Api.Employee.getEmployeeTrips(companyId, employeeId);
      store.dispatch({
        type: ACTIONS.EMPLOYEE.GET_TRIPS,
        res,
      });
    } catch (e) {
      store.dispatch({
        type: ACTIONS.EMPLOYEE.GET_TRIPS,
        res: [],
      });
    }
  };

  getEmployeeTripsByAccountId = async (accountId, employeeId) => {
    try {
      const res = await Api.Employee.getEmployeeTripsByAccountId(accountId, employeeId);
      store.dispatch({
        type: ACTIONS.EMPLOYEE.GET_TRIPS,
        res,
      });
    } catch (e) {
      store.dispatch({
        type: ACTIONS.EMPLOYEE.GET_TRIPS,
        res: [],
      });
    }
  };

  updateNotes = (employeeId, notes) => Api.Employee.updateNotes({ employeeId, notes });

  get = () => store.getState();

  subscribe = (callback) => store.subscribe(callback);
}

export default EmployeeService;
